import { Component, Input, OnDestroy } from '@angular/core';
import { MariaDialogsService } from '../../services/maria-dialogs.service';

@Component({
  selector: 'ia-button-fab',
  templateUrl: './maria-button-fab.component.html',
  styleUrls: ['../../style/maria.scss']
})
export class MariaButtonFabComponent implements OnDestroy {
  @Input() disabled: boolean;
  @Input() buttonFixAdjust: boolean;
  @Input() patientAppDigitalStatus: string;

  constructor(
    public mariaDialogsService: MariaDialogsService) {
  }

  ngOnDestroy() {
  }

  openChat() {
    this.mariaDialogsService.openChat().subscribe();
  }

}
