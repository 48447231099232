import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MariaChatDialogComponent } from '../dialog/maria-chat-dialog/maria-chat-dialog.component';
import {
  MariaChatRatingBadDialogComponent
} from '../dialog/maria-chat-rating-bad-dialog/maria-chat-rating-bad-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MariaDialogsService {
  constructor(
    readonly bottomSheet: MatBottomSheet,
    private dialog: MatDialog) {
  }

  public openChat(data?: any): Observable<boolean> {
    const supportsDVH = CSS.supports("height", "100dvh");
    const supportsDVW = CSS.supports("width", "100dvw");

    const dynamicHeight = supportsDVH ? '100dvh' : '100vh';
    const dynamicWidth = supportsDVW ? '100dvw' : '100vw';

    let dialogRef: MatDialogRef<MariaChatDialogComponent>;
    dialogRef = this.dialog.open(MariaChatDialogComponent, {
      panelClass: 'full-dialog',
      width: dynamicWidth,
      maxWidth: dynamicWidth,
      minWidth: dynamicWidth,
      minHeight: dynamicHeight,
      maxHeight: dynamicHeight,
      height: dynamicHeight,
      data,
    });

    return dialogRef.afterClosed();
  }

  public openPersonMessageRatingUserBad(): Observable<string> {
    const bottomSheetRef = this.bottomSheet.open(MariaChatRatingBadDialogComponent);
    return bottomSheetRef.afterDismissed();
  }
}
