<button
  [class.button-fix-adjust]="buttonFixAdjust"
  *featureFlagEnabled="['PATIENT_APP_MARIA']" (click)="openChat()" mat-ripple [disabled]="disabled"
        class="maria-button-fab test" type="button">
  <div class="star"></div>
  <span class="button-text">Ajuda</span>
</button>
<div *featureFlagEnabled="['PATIENT_APP_MARIA']">
  <ia-button-dialog-system [digitalStatus]="patientAppDigitalStatus"/>
</div>

<div class="maria-fab-space"></div>
