import { Injectable } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PdfPasswordService } from '../pdf-password/pdf-password.service';
import { UtilsService } from '../../../shared/services/utils.service';

GlobalWorkerOptions.workerSrc = '/assets/pdfjs/pdf.worker.min.mjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadPdfValidatorService {

  constructor(
    private utilsService: UtilsService,
    private pdfPasswordService: PdfPasswordService,
  ) { }

  checkPdfPassword(pdfFile: File): Observable<{ isProtected: boolean, pdfFile?: File, password?: string }> {
    return from(pdfFile.arrayBuffer()).pipe(
      switchMap((pdfData) => this.tryOpenPdf(pdfData, pdfFile)),
      catchError((error) => throwError(() => error))
    );
  }

  private tryOpenPdf(
    pdfData: ArrayBuffer,
    pdfFile: File,
    password?: string
  ): Observable<{ isProtected: boolean, pdfFile?: File, password?: string }> {
    const pdfOptions = { data: pdfData.slice(0), password };

    return from(pdfjsLib.getDocument(pdfOptions).promise).pipe(
      map(() => {
        return { isProtected: false, pdfFile, password };
      }),
      catchError((error) => {
        console.error(error);
        if (error.name === 'PasswordException') {
          if (
            error.code === pdfjsLib.PasswordResponses.NEED_PASSWORD ||
            error.code === pdfjsLib.PasswordResponses.INCORRECT_PASSWORD
          ) {
            if (error.code === pdfjsLib.PasswordResponses.INCORRECT_PASSWORD) {
              this.utilsService.toast('Senha incorreta', 'Ok', 4, 'top');
            }
            return this.pdfPasswordService.confirm().pipe(
              switchMap((enteredPassword) => {
                if (!enteredPassword) {
                  return throwError(() => new Error('Ciclo encerrado pelo usuário.'));
                }
                return this.tryOpenPdf(pdfData, pdfFile, enteredPassword);
              })
            );
          }
        }

        return throwError(() => error);
      })
    );
  }
}
