import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typingEffect',
  pure: false // Para garantir que o Angular detecte mudanças no estado interno do Pipe
})
export class TypingEffectPipe implements PipeTransform {
  private fullText = '';
  private currentText = '';
  private index = 0;
  private typingSpeed = 30; // Velocidade da digitação em ms
  private timer: any;
  private isActive = true; // Variável para ativar ou desativar o efeito

  transform(value: string, active: boolean = true): string {
    this.isActive = active;
    if (value !== this.fullText) {
      this.fullText = value;
      this.currentText = '';
      this.index = 0;
      if (this.isActive) {
        this.startTyping();
      } else {
        this.currentText = this.fullText;
      }
    }
    return this.currentText;
  }

  private startTyping(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      if (this.index < this.fullText.length) {
        this.currentText += this.fullText[this.index];
        this.index++;
      } else {
        clearInterval(this.timer);
      }
    }, this.typingSpeed);
  }
}
