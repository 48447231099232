import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { Person } from '../../../state/models/person';

@Injectable({
  providedIn: 'root',
})
export class MariaFakeApiService {

  enableFake = false;

  private person: Person = this.jwtAuthService.getUser();

  private responses = [
    {
      keywords: ['oi', 'olá'],
      responseContent: {
        plainText: {
          plainText: 'Olá, em que posso te ajudar hoje?'
        }
      }
    },
    {
      keywords: ['exames', 'pedido de exame'],
      responseContent: {
        plainText: {
          plainText: 'Seus pedidos de exames já estão disponíveis!'
        }
      }
    },
    {
      keywords: ['plano', 'saúde', 'convênio'],
      responseContent: {
        markdown: {
          markdown: 'Vi que seu plano de saúde é **Aurora Saúde**. Eu posso te ajudar a marcar seus exames. Pode compartilhar sua localização comigo?'
        }
      }
    },
    {
      keywords: ['sim'],
      responseContent: {
        plainText: {
          plainText: 'Vi que seu plano é Aurora Saúde. <br/><br/> Compartilhe sua localização comigo para que eu possa encontrar o laboratório mais próximo de você.\n'
        },
        buttonOptions: [
          {
            ref: 'B1',
            action: 'SEND_TEXT',
            plainText: 'Compartilhar',
            shortText: 'Compartilhar'
          },
        ]
      }
    },
    {
      keywords: ['compartilhar'],
      responseContent: {
        plainText: {
          plainText: 'O laboratório mais perto de você é o São Marcos da Av. Bernardo Monteiro em Belo Horizonte. <br/><br/> Escolha a opção de dia e horário abaixo que melhor te atende:\n'
        },
        buttonOptions: [
          {
            ref: 'B1',
            action: 'SEND_TEXT',
            plainText: '17/02 8h',
            shortText: '17/02 8h'
          },
          {
            ref: 'B2',
            action: 'SEND_TEXT',
            plainText: '17/02 9h',
            shortText: '17/02 9h'
          },
          {
            ref: 'B3',
            action: 'SEND_TEXT',
            plainText: '18/02 9h',
            shortText: '18/02 9h'
          }
        ]
      }
    },
    {
      keywords: ['/02', '18'],
      responseContent: {
        markdown: {
          markdown: 'Seu agendamento está pronto!  \n' +
            '\n' +
            'Aqui estão as informações:  \n' +
            '\n' +
            '- **Data:** 17/02/2025 8h  \n' +
            '- **Laboratório:** São Marcos  \n' +
            '- **Local:** Av. Bernardo Monteiro, 918 - Santa Efigênia, Belo Horizonte - MG, 30150-281  \n' +
            '\n' +
            'Basta chegar na data e local acima com o pedido em mãos e realizar seus exames **sem custos**!  \n' +
            'Se quiser, o contato do laboratório é: **(31) 2104-0100**  \n' +
            '\n' +
            'Qualquer dúvida, estou aqui para te ajudar.  '
        },
      }
    }
  ];

  constructor(private jwtAuthService: JwtAuthService) {

  }


  get getMessages$(): Observable<any> {
    console.log(this.getStoredMessages());
    return of(this.getStoredMessages());
  }

  sendResponse(personMessage: any): Observable<any> {

    let userMessage = personMessage.contentBlocks[0]?.plainText?.plainText;
    if (personMessage.contentBlocks[0].buttonClick) {
      userMessage = personMessage.contentBlocks[0].buttonClick?.plainText;
    }

    const userMessageObject = {
      id: this.generateUniqueId(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      conversationUniqueKey: this.generateConversationKey(),
      hidden: false,
      ...personMessage
    };

    this.storeMessage(userMessageObject);


    const foundResponse = this.responses.find((r) =>
      r.keywords.some(keyword => userMessage.toLowerCase().includes(keyword))
    );

    const contentBlocks = [];
    if (foundResponse) {
      for (const key in foundResponse.responseContent) {
        contentBlocks.push({
          type: key.replace(/([A-Z])/g, '_$1').toUpperCase(),
          [key]: foundResponse.responseContent[key]
        });
      }
    }

    const response = {
      id: this.generateUniqueId(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      conversationUniqueKey: this.generateConversationKey(),
      contentBlocks: contentBlocks.length ? contentBlocks : [{
        type: 'PLAIN_TEXT',
        plainText: {
          plainText: 'Desculpe, não entendi.'
        }
      }],
      appName: 'PATIENT',
      source: 'SYSTEM',
      hidden: false,
      triggerEvent: {
        type: 'PERSON_MESSAGE_CREATED',
        params: {
          personMessageId: personMessage.person.id
        }
      },
      person: {
        id: personMessage.person.id
      }
    };

    this.storeMessage(response);
    return of(this.getStoredMessages()).pipe(delay(6000));
  }

  private generateConversationKey(): string {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  private generateUniqueId(): number {
    return Date.now() + Math.floor(Math.random() * 1000);
  }

  private storeMessage(message: any) {
    const messages = this.getStoredMessages();
    messages.unshift(message);
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }

  private getStoredMessages(): any[] {
    const messages = localStorage.getItem('chatMessages');
    return messages ? JSON.parse(messages) : [];
  }

  setFirstInteractions(): void {

    if (this.getStoredMessages().length > 0) {
      return;
    }

    const response = {
      id: this.generateUniqueId(),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      conversationUniqueKey: this.generateConversationKey(),
      contentBlocks: [
        {
          type: 'PLAIN_TEXT',
          plainText: {
            plainText: `${ this.person.personProperties?.name?.split(' ')[0] }, seus pedidos de exames já estão disponíveis! <br/><br/> Posso te ajudar a agendar?`
          }
        },
        {
          type: 'BUTTON_OPTIONS',
          buttonOptions: [
            {
              ref: 'Sim',
              action: 'SEND_TEXT',
              plainText: 'Sim',
              shortText: 'Sim'
            },
            {
              ref: 'Não',
              action: 'SEND_TEXT',
              plainText: 'Não',
              shortText: 'Não'
            },

          ]
        }
      ],
      appName: 'PATIENT',
      source: 'SYSTEM',
      hidden: false,
      triggerEvent: {
        type: 'QUALQUERCOISA',
        params: {
          personMessageId: 1000
        }
      },
      person: {
        id: this.person.id
      }
    };
    this.storeMessage(response);
  }

  clearAllMessages(): void {
    localStorage.removeItem('chatMessages');
  }

}
