import { Component } from '@angular/core';

@Component({
  selector: 'maria-chat-skeleton-loader',
  template: `
    <div class="skeleton">
      <div class="skeleton-line"></div>
      <div class="skeleton-line"></div>
      <div class="skeleton-line small"></div>
    </div>
  `,
  styles: [`
    $skeleton-bg: linear-gradient(180deg, #F1F5FD 0%, #BFC9DF 100%);
    $skeleton-line-bg: rgba(255, 255, 255, 0.5);

    .skeleton {
      display: flex;
      width: 280px;
      height: 121px;
      padding: 22px 7px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 16px;
      background: $skeleton-bg;
      position: relative;
      overflow: hidden;
    }

    .skeleton::after {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%);
      animation: skeleton-loading 1.5s infinite;
    }

    @keyframes skeleton-loading {
      0% { left: -100%; }
      100% { left: 100%; }
    }

    .skeleton-line {
      width: 243px;
      height: 16px;
      background: $skeleton-line-bg;
      border-radius: 4px;
      opacity: 0.5;
    }

    .skeleton-line.small {
      width: 50%;
    }
  `]
})
export class MariaSkeletonLoaderComponent {}
