<h1 mat-dialog-title class="title-nav">
  <div class="title-internal">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <img src="/assets/image/maria/star.svg" alt="Star Ia"/>
    <div class='text' [innerHTML]="title | typingPhraseEffect"></div>
  </div>
</h1>
<div mat-dialog-content class="content" #scrollContainer>
  @if (messages.length > 0 && loadingFirstInteraction) {
    <div class="chat-messages">
      <ng-container *ngFor="let message of messages; let last = last; index as i">
        <div class="bubble-message" [class]="message.type">
          <div>
            @for (chat of message.chat; track chat) {
              @if (chat.type === 'text') {
                <span class="text"
                      [innerHTML]="chat.message | typingEffect: shouldTypingActivate(last, message.type)"></span>
              } @else {
                <span class="pt2 pb2">
                  <button [disabled]="loading" class="maria" (click)="goTo(chat.button)" mat-stroked-button>
                    {{ chat.button | stringFormat }}
                  </button>
                </span>
              }
            }
          </div>
        </div>
        <div class="rating" *ngIf="message.type === 'system'">
          <span class="text">Essa mensagem foi útil</span>
          <div>
            <button mat-button (click)="sendRatingMessage('GOOD', message)">
              <mat-icon [class.material-icons-full]="message?.rating === 'GOOD'">thumb_up</mat-icon>
              <span>Sim</span>
            </button>
            <button mat-button (click)="sendRatingMessage('BAD', message)">
              <mat-icon [class.material-icons-full]="message?.rating === 'BAD'">thumb_down</mat-icon>
              <span>Não</span>
            </button>
          </div>
        </div>
      </ng-container>
      <maria-chat-skeleton-loader *ngIf="loading"/>
    </div>
  } @else if (messages.length === 0 && loadingFirstInteraction) {
    <div class="chat-messages empty-state">
      <div class="center">
        <img src="/assets/image/maria/star-big.svg" alt="Star Ia Olá"/>
        <h2>Olá, {{ person?.personProperties?.name }}. Sou sua assistente de Saúde. Você tem alguma dúvida?</h2>
      </div>

      <div class="footer">
        <ng-container *ngTemplateOutlet="inputMessage;"/>
        <p>A Maria está em treinamento e por isso contamos com a sua ajuda. Caso ela não seja clara ou você tenha algo a
          reportar, fale com a gente.</p>
      </div>
    </div>
  }

  <div class="button-options" *ngIf="buttonOptions.length">
    @for (item of buttonOptions; track item) {
      <button [disabled]="loading" mat-stroked-button class="maria" (click)="sendMessage(item)">{{ item.shortText }}
      </button>
    }
  </div>
</div>
<div mat-dialog-actions *ngIf="loadingFirstInteraction && messages.length > 0">
  <ng-container *ngTemplateOutlet="inputMessage;"/>
</div>

<ng-template #inputMessage let-messages>
  <div class="form-wrap">
    <div class="form-wrap-container">
      <div class="form-custom">
        <mat-form-field class="w-100">
          <input (keydown.enter)="sendMessage()" [formControl]="messageControl" matInput #message
                 placeholder="Escreva sua pergunta aqui."/>
        </mat-form-field>
      </div>
      <button [disabled]="loading || !messageControl?.value" type="button"
              (click)="sendMessage(); $event.preventDefault()">
        <img src="/assets/image/maria/star-send.svg" alt="Enviar"/>
      </button>
    </div>
  </div>
</ng-template>
