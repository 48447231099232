import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { DataConfirm } from './confirm.service';

@Component({
  selector: 'confirm',
  styleUrls: ['./confirm.component.scss'],
  template: `
    <mat-card>
      <h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
      <div mat-dialog-content class="mb-16">
        <p [innerHTML]="data.message"></p>
      </div>
      <div mat-dialog-actions class="pb-16" *ngIf="data?.buttonCancel?.show || data?.buttonConfirm?.show">
        <div fxFill fxLayout="row" fxLayout.xs="column" fxLayoutGap="8px">
          <div fxFlex="100">
            <button
              [class.maria]="data.theme === 'maria'"
              fxFlex="100"
              mat-raised-button
              type="submit"
              color="primary"
              *ngIf="data?.buttonConfirm?.show"
              (click)="dialogRef.close(true)"
              class="w-100 ml-none"
            >
              {{ data?.buttonConfirm?.label }}
            </button>
          </div>
          <div fxFlex="100">
            <button
              [class.maria]="data.theme === 'maria'"
              fxFlex="100"
              mat-dialog-close
              mat-stroked-button
              type="button"
              *ngIf="data?.buttonCancel?.show"
              (click)="dialogRef.close(false)"
            >
              {{ data?.buttonCancel?.label }}
            </button>
          </div>
    
        </div>
      </div>
    </mat-card>
  `,
})
export class ConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataConfirm,
  ) {
  }
}
