<button *featureFlagEnabled="['PATIENT_APP_MARIA']" (click)="openChat()" [disabled]="disabled"
        class="maria-button-large" [class.w-100]="fulWidth" type="button">
  <div class="star"></div>
  <span class="button-text">
    Olá, posso te ajudar hoje?
  </span>
  <mat-icon>keyboard_arrow_right</mat-icon>
</button>
<div *featureFlagEnabled="['PATIENT_APP_MARIA']">
  <ia-button-dialog-system [digitalStatus]="patientAppDigitalStatus" [btnLarge]="true"/>
</div>
