<mat-toolbar class="toolbar">
  <button mat-icon-button (click)="matBottomSheet.dismiss()">
    <mat-icon>arrow_back</mat-icon>
  </button>
</mat-toolbar>
<div class="wrap">
  <p>
    Agradecemos pelo seu feedback. Nos ajude a entender mais o que aconteceu para melhorar o treinamento da Assistente de Saúde
  </p>
  <mat-card>
    <mat-card-content>
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group"
        [(ngModel)]="answer">
        @for (answer of answers; track answer; let last = $last) {
          <mat-radio-button class="example-radio-button" [value]="answer">{{ answer }}</mat-radio-button>
          <mat-divider *ngIf="!last"/>
        }
      </mat-radio-group>
    </mat-card-content>
  </mat-card>

  <button class="maria-button-raised" mat-raised-button (click)="matBottomSheet.dismiss(answer || 'Outros')">
    <span>Enviar</span>
  </button>
</div>
