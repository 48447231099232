import { Injectable } from '@angular/core';
import { ButtonOptions, ContentBlock, PersonMessage } from '../../../state/models/person-messages';
import { MessageByType } from '../dialog/maria-chat-dialog/maria-chat-dialog.component';
import { marked } from 'marked';
import { UrlService } from '../../services/url.service';
import { WhatsappService } from '../../services/whatsapp.service';
import { UtilsService } from '../../services/utils.service';
import { LocalStoreService } from '../../services/local-store.service';

@Injectable({
  providedIn: 'root'
})
export class MariaUtilsService {

  userFirstChatOpen = 'USER_FIRST_CHAT_OPEN';

  constructor(
    private localStorageService: LocalStoreService,
    private whatsappService: WhatsappService,
    private urlService: UrlService,
    private utilsService: UtilsService,
  ) {
  }

  public get hasUserFirstChatOpen(): boolean {
    return !!this.localStorageService.getItem(this.userFirstChatOpen);
  }

  public setUserFirstChatOpen() {
    this.localStorageService.setItem(this.userFirstChatOpen, true);
  }

  public getUrlByGoToEnums(goTo: string, digitalStatus: string) {
    if (goTo === 'PATIENT_HUMAN_SUPPORT') {
      const msg = 'Falei com sua Assistente de Saúde, mas continuo precisando de ajuda.';
      this.whatsappService.sendMessage(msg);
    }
    if (goTo === 'PATIENT_PREVIOUS_EXAMS' && digitalStatus === 'WAITING_PREVIOUS_EXAMS') {
      return this.urlService.personHomePreviousExams();
    }
    if (goTo === 'PATIENT_HOME' && ['WAITING_PERFORMED_EXAM', 'FINISHED'].includes(digitalStatus)) {
      return this.urlService.personHome();
    }
    if (goTo === 'PATIENT_EXAM_REQUEST_DOWNLOAD' && digitalStatus === 'WAITING_PERFORMED_EXAM') {
      return this.urlService.personHomeExamRequest();
    }
    if (goTo === 'PATIENT_EXAM_REQUEST_REASONS' && digitalStatus === 'WAITING_PERFORMED_EXAM') {
      return this.urlService.personHomeExamRequestReason();
    }
    if (goTo === 'PATIENT_EXAM_REQUEST_INSTRUCTIONS' && digitalStatus === 'WAITING_PERFORMED_EXAM') {
      return this.urlService.personHomeExamRequestInstructions();
    }
    if (goTo === 'PATIENT_PERFORMED_EXAM_UPLOAD' && digitalStatus === 'WAITING_PERFORMED_EXAM') {
      return this.urlService.personHomeExamResults();
    }
    if (goTo === 'PATIENT_SUMMARY' && ['FINISHED', 'WAITING_PERFORMED_EXAM'].includes(digitalStatus)) {
      if (digitalStatus === 'WAITING_PERFORMED_EXAM') {
        return this.urlService.personHomeSummary();
      }
      return this.urlService.personSummary();
    }
    if (goTo === 'PATIENT_PERFORMED_EXAM_DOWNLOAD' && digitalStatus === 'FINISHED') {
      return this.urlService.personSummaryResult();
    }

    if (goTo !== 'PATIENT_HUMAN_SUPPORT') {
      this.utilsService.toast('O botão clicado já não é mais válido.', 'Ok');
    }

    if (digitalStatus !== 'WAITING_PREVIOUS_EXAMS') {
      return this.urlService.personHome();
    }
  }

  getMessageByType(contentBlocks: ContentBlock[], source: PersonMessage['source']): GetMessageByType {
    let messages: MessageByType[] = [];
    let buttonOptionsArr = [];
    console.log(contentBlocks);
    contentBlocks.forEach(contentBlock => {
      if (contentBlock.type === 'PLAIN_TEXT') {
        messages.push({ type: 'text', message: contentBlock.plainText.plainText });
      }
      if (contentBlock.type === 'MARKDOWN') {
        messages.push({ type: 'text', message: marked(contentBlock.markdown.markdown) });
      }
      if (contentBlock.type === 'GO_TO') {
        messages.push({ type: 'button', button: contentBlock.goTo.destination });
      }
      if (contentBlock.type === 'BUTTON_OPTIONS') {
        const { buttonOptions = [] } = contentBlock;
        if (source === 'SYSTEM') {
          buttonOptionsArr = buttonOptions;
        } else if (source === 'USER') {
          const [buttonOption] = buttonOptions;
          const { plainText } = buttonOption;
          messages.push({ type: 'text', message: plainText });
        }
      }
    });
    return { messages, buttonOptions: buttonOptionsArr };
  }

}

export interface GetMessageByType {
  messages: MessageByType[],
  buttonOptions: ButtonOptions[]
}
