import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typingPhraseEffect',
  pure: false // Para garantir que o Angular detecte mudanças no estado interno do Pipe
})
export class TypingPhraseEffectPipe implements PipeTransform {
  private phrases: string[] = [];
  private currentText = '';
  private phraseIndex = 0;
  private charIndex = 0;
  private typingSpeed = 30; // Velocidade da digitação em ms
  private deletingSpeed = 20; // Velocidade da deleção em ms
  private timer: any;
  private isActive = true;

  transform(value: string[], active: boolean = true): string {
    this.isActive = active;
    if (JSON.stringify(value) !== JSON.stringify(this.phrases)) {
      this.phrases = value;
      this.currentText = '';
      this.phraseIndex = 0;
      this.charIndex = 0;
      if (this.isActive && this.phrases.length > 0) {
        this.startTyping();
      } else {
        this.currentText = this.phrases[this.phrases.length - 1] || '';
      }
    }
    return this.currentText;
  }

  private startTyping(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.currentText = '';
    this.charIndex = 0;

    this.timer = setInterval(() => {
      if (this.charIndex < this.phrases[this.phraseIndex].length) {
        this.currentText += this.phrases[this.phraseIndex][this.charIndex];
        this.charIndex++;
      } else {
        clearInterval(this.timer);
        if (this.phraseIndex < this.phrases.length - 1) {
          setTimeout(() => {
            this.startDeleting();
          }, 3000); // Pequena pausa antes de apagar
        }
      }
    }, this.typingSpeed);
  }

  private startDeleting(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      if (this.currentText.length > 0) {
        this.currentText = this.currentText.slice(0, -1);
      } else {
        clearInterval(this.timer);
        this.phraseIndex++;
        this.charIndex = 0;
        if (this.phraseIndex < this.phrases.length) {
          setTimeout(() => this.startTyping(), 500);
        }
      }
    }, this.deletingSpeed);
  }
}
