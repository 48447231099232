import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { SubscriptionEntityService } from '../../state/entity-services/subscription-entity.service';
import { map } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';
import { Subscription } from '../../state/models/subscription';
import { Organization } from '../../state/models/organization';
import { PersonEntityService } from '../../state/entity-services/person-entity.service';
import { FeatureFlagService } from '../services/feature-flag.service';

@Injectable()
export class OrganizationInitGuard implements CanActivate {
  constructor(
    private featureFlagService: FeatureFlagService,
    private personEntityService: PersonEntityService,
    private organizationService: OrganizationService,
    private subscriptionEntityService: SubscriptionEntityService,
    private router: Router, private jwtAuth: JwtAuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.jwtAuth.isLoggedIn) {
      return of(this.organizationService.setOrganization());
    }

    return this.getUserSubscription().pipe(
      map(subscription => {
        const organizationMar = this.getOrganizationFromSubscription(subscription);
        return this.organizationService.setOrganization(organizationMar);
      }),
      switchMap(response => {
        const { id } = this.organizationService.getOrganization();
        return this.featureFlagService.getFeatureFlagSearchesByOrgId(id).pipe(
          map(() => {
            return response;
          })
        );
      })
    );
  }

  private getUserSubscription(): Observable<Subscription> {
    return this.jwtAuth.getUser() ? this.fetchUserSubscription() : this.handleInvalidToken();
  }

  private fetchUserSubscription(): Observable<Subscription> {
    const user = this.jwtAuth.getUser();
    return this.querySubscription(user.id);
  }

  private handleInvalidToken(): Observable<Subscription> {
    return this.jwtAuth.checkTokenIsValid().pipe(
      switchMap(() => this.fetchUserSubscription())
    );
  }

  private querySubscription(userId: number): Observable<Subscription> {
    return forkJoin([this.subscriptionEntityService.getWithQuery({
      personIds: userId,
      active: true
    }), this.personEntityService.getSelf()]).pipe(
      map(response => {
        const [subscriptions] = response;
        return subscriptions;
      }),
      map(subscriptions => {
        return subscriptions.find(subscription => !subscription.organization.parent);
      }),
    );
  }

  private getOrganizationFromSubscription(subscription?: Subscription): Organization | undefined {
    return subscription?.organization;
  }
}


