import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  organizationsList(): string {
    return '/organizacoes';
  }

  organizationsBase(id: unknown): string {
    return [this.organizationsList(), id].join('/');
  }

  personList(): string {
    return '/pessoas';
  }

  personDetailsBase(id: unknown) {
    return [this.personList(), id].join('/');
  }

  personDetails(id: unknown) {
    const url = [this.personDetailsBase(id), 'exames'];
    return url.join('/');
  }

  personDetailsEdit(id: unknown) {
    const url = [this.personDetailsBase(id), 'dados-paciente'];
    return url.join('/');
  }

  personDetailsReferrals(id: unknown) {
    const url = [this.personDetailsBase(id), 'encaminhamentos'];
    return url.join('/');
  }

  personDetailsFindings(id: unknown) {
    const url = [this.personDetailsBase(id), 'diagnosticos'];
    return url.join('/');
  }

  personDetailsPrevious(id: unknown) {
    const url = [this.personDetailsBase(id), 'previous'];
    return url.join('/');
  }

  personDetailsRisk(personId: unknown, id?: unknown) {
    let url;
    if (id) {
      url = [this.personDetailsBase(personId), 'riscos', id];
    } else {
      url = [this.personDetailsBase(personId), 'riscos'];
    }
    return url.join('/');
  }

  personDetailsLifeHabits(personId: unknown, id?: unknown) {
    let url;
    if (id) {
      url = [this.personDetailsBase(personId), 'habitos-de-vida', id];
    } else {
      url = [this.personDetailsBase(personId), 'habitos-de-vida'];
    }
    return url.join('/');
  }

  personDetailsForms(id: unknown, formSubmissionId?) {
    let url;
    if (formSubmissionId) {
      url = [this.personDetailsBase(id), 'formulario', formSubmissionId];
    } else {
      url = [this.personDetailsBase(id), 'formulario'];
    }
    return url.join('/');
  }

  personDetailsHistory(id: unknown, formSubmissionId?) {
    let url;
    if (formSubmissionId) {
      url = [this.personDetailsBase(id), 'historico', formSubmissionId];
    } else {
      url = [this.personDetailsBase(id), 'historico'];
    }
    return url.join('/');
  }

  personStatus(): string {
    let url = ['/paciente', 'ending'];
    return url.join('/');
  }

  personWaitingPage(): string {
    let url = ['/paciente', 'aguardando'];
    return url.join('/');
  }

  personHome(): string {
    let url = ['/paciente', 'home'];
    return url.join('/');
  }

  personHomeWomanArea(): string {
    let url = [this.personHome(), 'area-da-mulher'];
    return url.join('/');
  }

  personHomeWomanAreaAboutViolence(): string {
    let url = [this.personHomeWomanArea(), 'sofri-mesmo-violencia-duvida'];
    return url.join('/');
  }

  personHomeWomanAreaViolenceWhatNow(): string {
    let url = [this.personHomeWomanArea(), 'sofri-violencia-e-agora'];
    return url.join('/');
  }

  personHomeWomanAreaWhereToGetHelp(): string {
    let url = [this.personHomeWomanArea(), 'onde-conseguir-ajuda'];
    return url.join('/');
  }

  personHomeWomanAreaSafetyPlan(): string {
    let url = [this.personHomeWomanArea(), 'plano-de-seguranca'];
    return url.join('/');
  }

  personHomeExamResults(): string {
    let url = [this.personHome(), 'enviar-exames'];
    return url.join('/');
  }

  personHomeExamRequest(): string {
    let url = [this.personHome(), 'pedidos-de-exame'];
    return url.join('/');
  }

  personHomeExamRequestInstructions(): string {
    let url = [this.personHome(), 'preparo-de-exame'];
    return url.join('/');
  }

  personHomeExamRequestReason(): string {
    let url = [this.personHome(), 'motivos-solicitacao'];
    return url.join('/');
  }

  personHomeSummary(): string {
    let url = [this.personHome(), 'relatorio-saude-parcial'];
    return url.join('/');
  }

  personHomePreviousExams(): string {
    let url = [this.personHome(), 'exames-previos'];
    return url.join('/');
  }

  personSummaryHome(personId?: number): string {
    return this.personHome();
  }

  personSummary(personId?: number): string {
    let url = [this.personSummaryHome(), 'relatorio-saude'];
    return url.join('/');
  }

  personSummaryResult(personId?: number): string {
    let url = [this.personSummaryHome(), 'resultados-exames'];
    return url.join('/');
  }

}
