import { Component, Input } from '@angular/core';
import { MariaDialogsService } from '../../services/maria-dialogs.service';
import { MariaFakeApiService } from '../../services/maria-fake-api.service';

@Component({
  selector: 'ia-button-large',
  templateUrl: './maria-button-large.component.html',
  styleUrls: ['../../style/maria.scss']
})
export class MariaButtonLargeComponent {
  @Input() disabled: boolean;
  @Input() fulWidth: boolean = false;
  @Input() patientAppDigitalStatus: string;

  constructor(
    private mariaFakeApiService: MariaFakeApiService,
    public mariaDialogsService: MariaDialogsService) {
  }

  openChat() {
    this.mariaDialogsService.openChat().subscribe({
      next: result => {
        this.mariaFakeApiService.clearAllMessages()
      }
    });
  }


}
