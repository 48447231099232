import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'maria-chat-rating-bad-dialog',
  templateUrl: './maria-chat-rating-bad-dialog.component.html',
  styleUrls: ['./maria-chat-rating-bad-dialog.component.scss']
})
export class MariaChatRatingBadDialogComponent {
  answer: string;

  answers: string[] = [
    'Não foi relevante',
    'Informação incorreta',
    'Muito repetitivo',
    'Conteúdo ofensivo',
  ];

  constructor(
    public matBottomSheet: MatBottomSheet,
    @Inject(MAT_BOTTOM_SHEET_DATA) public any: number,
  ) {
    this.answers = this.answers.map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    this.answers.push('Outros');
  }
}
