import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringFormatPipe } from './string-format.pipe';
import { NumeralPipe } from './numeral.pipe';
import { DatePipe } from './date.pipe';
import { TranslationPipe } from './translation.pipe';
import { SafePipe } from './safe.pipe';
import { PersonReturnIconPipe } from './person-return-icon.pipe';
import { TypingEffectPipe } from './typing-effect.pipe';
import { TypingPhraseEffectPipe } from './typing-phrase-effect.pipe';

const pipes = [
  TypingPhraseEffectPipe,
  TypingEffectPipe,
  DatePipe,
  NumeralPipe,
  PersonReturnIconPipe,
  SafePipe,
  StringFormatPipe,
  TranslationPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes,
})
export class SharedPipesModule {
}
