import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from '../shared-material.module';
import { MariaButtonLargeComponent } from './components/maria-button-large/maria-button-large.component';
import { MariaButtonFabComponent } from './components/maria-button-fab/maria-button-fab.component';
import { MariaChatDialogComponent } from './dialog/maria-chat-dialog/maria-chat-dialog.component';
import { MariaSkeletonLoaderComponent, } from './components/maria-chat-skeleton-loader/maria-chat-skeleton-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import {
  MariaChatRatingBadDialogComponent
} from './dialog/maria-chat-rating-bad-dialog/maria-chat-rating-bad-dialog.component';
import {
  MariaButtonDialogSystemComponent
} from './components/maria-button-dialog-system/maria-button-dialog-system.component';
import { SharedDirectivesModule } from '../directives/shared.directives.module';

// ONLY FOR DEMO
// ALWAYS REQUIRED

const components = [
  MariaButtonDialogSystemComponent,
  MariaChatRatingBadDialogComponent,
  MariaSkeletonLoaderComponent,
  MariaChatDialogComponent,
  MariaButtonFabComponent,
  MariaButtonLargeComponent,
];

@NgModule({
  imports: [
    SharedPipesModule,
    CommonModule,
    RouterModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SharedDirectivesModule,
  ],
  declarations: components,
  exports: components
})
export class MariaModule {
}
